import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type GenerateClientAccessTokenWithOtpMutationVariables = Types.Exact<{
  code: Types.Scalars["String"]["input"];
  medspaSlug: Types.Scalars["String"]["input"];
  clientPhone?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  clientEmail?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GenerateClientAccessTokenWithOtpMutation = {
  __typename?: "mutation_root";
  generateClientAccessTokenWithOtp?: {
    __typename?: "GenerateClientAccessTokenWithOTP";
    clientAccessToken?: string | null;
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const GenerateClientAccessTokenWithOtpDocument = gql`
  mutation GenerateClientAccessTokenWithOtp(
    $code: String!
    $medspaSlug: String!
    $clientPhone: String
    $clientEmail: String
  ) {
    generateClientAccessTokenWithOtp(
      code: $code
      medspaSlug: $medspaSlug
      clientPhone: $clientPhone
      clientEmail: $clientEmail
    ) {
      clientAccessToken
      message
      ok
    }
  }
`;
export type GenerateClientAccessTokenWithOtpMutationFn =
  Apollo.MutationFunction<
    GenerateClientAccessTokenWithOtpMutation,
    GenerateClientAccessTokenWithOtpMutationVariables
  >;

/**
 * __useGenerateClientAccessTokenWithOtpMutation__
 *
 * To run a mutation, you first call `useGenerateClientAccessTokenWithOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateClientAccessTokenWithOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateClientAccessTokenWithOtpMutation, { data, loading, error }] = useGenerateClientAccessTokenWithOtpMutation({
 *   variables: {
 *      code: // value for 'code'
 *      medspaSlug: // value for 'medspaSlug'
 *      clientPhone: // value for 'clientPhone'
 *      clientEmail: // value for 'clientEmail'
 *   },
 * });
 */
export function useGenerateClientAccessTokenWithOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateClientAccessTokenWithOtpMutation,
    GenerateClientAccessTokenWithOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateClientAccessTokenWithOtpMutation,
    GenerateClientAccessTokenWithOtpMutationVariables
  >(GenerateClientAccessTokenWithOtpDocument, options);
}
export type GenerateClientAccessTokenWithOtpMutationHookResult = ReturnType<
  typeof useGenerateClientAccessTokenWithOtpMutation
>;
export type GenerateClientAccessTokenWithOtpMutationResult =
  Apollo.MutationResult<GenerateClientAccessTokenWithOtpMutation>;
export type GenerateClientAccessTokenWithOtpMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateClientAccessTokenWithOtpMutation,
    GenerateClientAccessTokenWithOtpMutationVariables
  >;
