import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { createContext, useContext, useState } from "react";
import toast from "react-hot-toast";
import { useRefreshClientAccessTokenMutation } from "@/graphql/mutations/refreshClientAccessToken.graphql.types";
import { CLIENT_ACCESS_TOKEN_COOKIE_KEY } from "@/hooks/client/useClientOTP";
import useIsOTPClientPage from "@/hooks/client/useIsOTPClientPage";
import { useEffectOnce } from "@/hooks/misc/useEffectOnce";
import useIsOnlineStoreOTPPage from "@/hooks/packages/useIsOnlineStoreOTPPage";
import { setCookieWithHoursOfExpiration } from "@/utils";
import { hasDjangoMutationError } from "@/utils/djangoMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

const ClientAccessTokenContext = createContext(null);

export const HOURS_OF_INACTIVITY_BEFORE_EXPIRATION = 24;

export const ClientAccessTokenProvider = ({ children }) => {
  const logError = useErrorLogger();
  const { query, pathname, push, asPath } = useRouter();

  const pagePath = usePagePath();
  const isClientPortalPage = useIsOTPClientPage();
  const isOnlineStorePage = useIsOnlineStoreOTPPage();

  const [refreshToken] = useRefreshClientAccessTokenMutation();

  const { medspaSlug, token: tokenFromURL } = query;
  const tokenFromCookie = Cookies.get(CLIENT_ACCESS_TOKEN_COOKIE_KEY);
  const [clientAccessToken, setClientAccessToken] = useState(
    tokenFromURL || tokenFromCookie
  );

  /**
   * Keep trying to get the client access token from the URL or cookie
   * if it didn't get set on the first render
   */
  useEffectOnce(
    Boolean(!clientAccessToken && (tokenFromURL || tokenFromCookie)),
    () => {
      setClientAccessToken(tokenFromURL || tokenFromCookie);
    }
  );

  const isSignInPage = pathname.includes("sign-in");

  const refreshClientAccessToken = async (token, medspaSlug) => {
    const { data } = await refreshToken({
      variables: {
        clientAccessToken: token as string,
        medspaSlug: medspaSlug as string,
      },
    });

    const returnedToken = data?.refreshClientAccessToken?.clientAccessToken;

    if (returnedToken) {
      setCookieWithHoursOfExpiration(
        CLIENT_ACCESS_TOKEN_COOKIE_KEY,
        returnedToken,
        HOURS_OF_INACTIVITY_BEFORE_EXPIRATION
      );
      if (returnedToken !== token) {
        setClientAccessToken(data.refreshClientAccessToken.clientAccessToken);
      }
      if (isSignInPage) {
        push(`/${pagePath}/${medspaSlug}`);
      }
    }
  };

  const redirectToSignIn = () => {
    if (!isSignInPage) {
      const redirectTo =
        pathname !== `/client/[medspaSlug]/appointments` ? asPath : "";

      const queryString = redirectTo
        ? `?redirectTo=${encodeURIComponent(redirectTo)}`
        : "";

      /**
       * Using this method instead of next/router.push to force a full page reload
       * so the provider/context can be re-initialized
       */
      if (pagePath && medspaSlug) {
        window.location.href = `/${pagePath}/${medspaSlug}/sign-in${queryString}`;
      } else {
        logError("Failed to redirect to OTP pages.");
      }
    }
  };

  const onOTPPageMount = () => {
    try {
      if (clientAccessToken && tokenFromCookie) {
        refreshClientAccessToken(clientAccessToken, medspaSlug);
      } else {
        redirectToSignIn();
      }
    } catch (error) {
      toast.error(
        hasDjangoMutationError(error)
          ? error.message
          : "Failed to refresh client access token or redirect to OTP pages."
      );
      logError(error);
    }
  };

  /**
   * The onOTPPageMount function should be called only once when the app starts,
   * and only if the user is on an page with OTP as a requirement:
   * - Client Portal (/client/[medspaSlug]/*)
   * - Memberships Confirm (/packages/[medspaSlug]/[tab]/[membershipId]/confirm)
   */
  useEffectOnce(
    Boolean((isClientPortalPage || isOnlineStorePage) && medspaSlug),
    onOTPPageMount
  );

  return (
    <ClientAccessTokenContext.Provider
      value={{
        clientAccessToken: clientAccessToken as string,
        medspaSlug: medspaSlug as string,
      }}
    >
      {children}
    </ClientAccessTokenContext.Provider>
  );
};

const usePagePath = () => {
  const isClientPortalPage = useIsOTPClientPage();
  const isOnlineStorePage = useIsOnlineStoreOTPPage();

  let pagePath;

  if (isClientPortalPage) {
    pagePath = "client";
  }

  if (isOnlineStorePage) {
    pagePath = "packages";
  }

  return pagePath;
};

export const useClientAccessToken = () => {
  return useContext(ClientAccessTokenContext) || {};
};

export default ClientAccessTokenProvider;
