import { UserButton } from "@clerk/nextjs";
import { Box, Stack } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { forwardRef } from "react";
import { AuthUser, useUser } from "@/auth/useUser";
import { GREY, WHITE } from "@/config/mui/colorPalette";
import { useResponsive } from "@/hooks/common/useResponsive";
import { getDefaultRoute } from "@/utils";
import { CrossIcon, MenuHamburgerIcon } from "../icons";
import ImpersonationBar from "./impersonationBar";
import MaintenanceBanner from "./maintenanceBanner";
import TopBarActions from "./topBarActions";
import { NAV_WIDTH } from "./verticalNav";

type Props = {
  onToggleNav: () => void;
  user: AuthUser;
  isNavOpen: boolean;
};

export const TOP_BAR_HEIGHT = "60px";

const TopBar = forwardRef<HTMLDivElement, Props>(function TopBarNavigation(
  { onToggleNav, user, isNavOpen },
  ref
) {
  const { medspa } = useUser();
  const mdUp = useResponsive("up", "md");

  return (
    <Box
      ref={ref}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        position: "fixed",
        top: 0,
        width: {
          xs: "100%",
          md: `calc(100% - ${NAV_WIDTH})`,
        },
        left: { xs: "0", md: NAV_WIDTH },
      }}
    >
      <MaintenanceBanner />
      <ImpersonationBar />
      <Box
        sx={{
          py: 1.5,
          px: 3,
          height: TOP_BAR_HEIGHT,
          backgroundColor: WHITE,
          borderBottom: `1px solid ${GREY[30]}`,
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignContent="center" spacing={2}>
            {!mdUp && (
              <Stack direction="row" spacing={2} alignItems="center">
                {isNavOpen ? (
                  <CrossIcon onClick={onToggleNav} color={GREY[60]} />
                ) : (
                  <MenuHamburgerIcon onClick={onToggleNav} color={GREY[60]} />
                )}
                <Box
                  component={Link}
                  href={getDefaultRoute({
                    medspa: { id: medspa },
                    role: user?.role,
                  })}
                >
                  <Image
                    src="/assets/moxie-logo.svg"
                    width={80}
                    height={20}
                    alt="Moxie logo"
                  />
                </Box>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" alignContent="center">
            <TopBarActions user={user} />
            <UserButton afterSignOutUrl="/sign-in" />
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
});

export default TopBar;
