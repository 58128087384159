import {
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OptionsModal, { OptionModalProps } from "../modals/optionsModal";
import { Option, Props } from "./radioOptionsTypes";

export default function RadioModal<O extends Option = Option>({
  activeOptionId: defaultFilterId,
  options,
  onOptionChange,
  onClose,
  open,
  title,
  buttonLabel = "Show results",
}: Props<O> & OptionModalProps) {
  const [activeFilterId, setActiveFilterId] = useState(defaultFilterId);

  useEffect(() => {
    if (open && defaultFilterId !== activeFilterId)
      setActiveFilterId(defaultFilterId);
  }, [open]);

  const onSubmit = () => {
    onOptionChange(options.find(({ id }) => id === activeFilterId));
    onClose();
  };

  const handleClose = () => {
    setActiveFilterId(defaultFilterId);
    onClose();
  };

  return (
    <OptionsModal
      onClose={handleClose}
      open={open}
      title={title}
      onSubmit={onSubmit}
      buttonLabel={buttonLabel}
    >
      <RadioGroup
        aria-label="choose-sort-options"
        name="sort-options-radio-buttons-group"
        value={activeFilterId}
        onChange={({ target }) => setActiveFilterId(target.value)}
      >
        <Stack divider={<Divider sx={{ my: 1 }} />}>
          {options.map(({ id, label }) => (
            <FormControlLabel
              key={id}
              value={id}
              label={label}
              control={<Radio />}
            />
          ))}
        </Stack>
        <Divider sx={{ mt: 1 }} />
      </RadioGroup>
    </OptionsModal>
  );
}
