import { createContext, ReactNode, useContext, useRef, useState } from "react";
import ConfirmModal from "../common/modals/confirmModal";

export type OpenConfirmOptions = {
  title: string;
  description: ReactNode;
  discardButtonText?: string;
  confirmButtonText: string;
  confirmButtonDisabled?: boolean;
};

export type ConfirmCheckboxOptions = {
  label: string;
  defaultValue: boolean;
};

type CheckboxReturnType = {
  checkboxValue: boolean;
  confirmed: boolean;
};

type GetConfirmReturnType = boolean | CheckboxReturnType;

type ConfirmContext = {
  getConfirm: (confirmOptions: OpenConfirmOptions) => Promise<boolean>;
  getConfirmWithCheckbox: (
    confirmOptions: OpenConfirmOptions,
    checkboxOptions: ConfirmCheckboxOptions
  ) => Promise<CheckboxReturnType>;
};

type Resolver = (
  value: GetConfirmReturnType | PromiseLike<GetConfirmReturnType>
) => void;

const ConfirmContext = createContext({} as ConfirmContext);

export default function ConfirmProvider({ children }: { children: ReactNode }) {
  const resolverRef = useRef<Resolver>();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState<OpenConfirmOptions>(
    DEFAULT_CONFIRM_OPTIONS
  );
  const [checkboxOptions, setCheckboxOptions] =
    useState<ConfirmCheckboxOptions>(null);

  const discard = (checkboxValue?: boolean) => {
    resolverRef.current(
      checkboxOptions ? { checkboxValue, confirmed: false } : false
    );
    clearAndClose();
  };

  const confirm = (checkboxValue?: boolean) => {
    resolverRef.current(
      checkboxOptions ? { checkboxValue, confirmed: true } : true
    );
    clearAndClose();
  };

  const clearAndClose = () => {
    resolverRef.current = undefined;
    setIsConfirmOpen(false);
    setCheckboxOptions(null);
  };

  const getConfirm = async (confirmOptions: OpenConfirmOptions) => {
    const promise = new Promise<boolean>((resolve) => {
      resolverRef.current = resolve as Resolver;
    });
    setConfirmOptions(confirmOptions);
    setCheckboxOptions(null);
    setIsConfirmOpen(true);
    return promise;
  };

  const getConfirmWithCheckbox = async (
    confirmOptions: OpenConfirmOptions,
    checkboxOptions: ConfirmCheckboxOptions
  ) => {
    const promise = new Promise<CheckboxReturnType>((resolve) => {
      resolverRef.current = resolve as Resolver;
    });
    setConfirmOptions(confirmOptions);
    setCheckboxOptions(checkboxOptions);
    setIsConfirmOpen(true);
    return promise;
  };

  return (
    <>
      <ConfirmContext.Provider
        value={{
          getConfirm,
          getConfirmWithCheckbox,
        }}
      >
        {children}
        <ConfirmModal
          isConfirmOpen={isConfirmOpen}
          confirm={confirm}
          discard={discard}
          confirmOptions={confirmOptions}
          checkboxOptions={checkboxOptions}
        />
      </ConfirmContext.Provider>
    </>
  );
}

export const useConfirm = () => useContext(ConfirmContext);

export const DEFAULT_CONFIRM_OPTIONS = {
  title: "Save changes?",
  description: "If you go back now, your changes will be discarded.",
  discardButtonText: "Discard",
  confirmButtonText: "Save",
};

export const DISCARD_OPTIONS = {
  title: "Discard changes?",
  description: "If you leave page, your changes will be discarded.",
  discardButtonText: "Discard",
  confirmButtonText: "Stay on page",
};
