import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { HOURS_OF_INACTIVITY_BEFORE_EXPIRATION } from "@/contexts/clientAccessTokenContext";
import { useGenerateClientAccessTokenWithOtpMutation } from "@/graphql/mutations/generateClientAccessTokenWithOtp.graphql.types";
import { useGenerateClientPortalOtpMutation } from "@/graphql/mutations/generateClientPortalOtp.graphql.types";
import useIsOTPClientPage from "@/hooks/client/useIsOTPClientPage";
import useAction from "@/hooks/misc/useAction";
import useIsPackagesPage from "@/hooks/packages/useIsPackagesPage";
import { setCookieWithHoursOfExpiration } from "@/utils";
import handleOtpMutationError from "@/utils/otpMutationError";
import useErrorLogger from "@/utils/useErrorLogger";

export const CLIENT_ACCESS_TOKEN_COOKIE_KEY = "__moxie_cat";
export const OTP_VALUE_LENGTH = 6;

export const useClientOTP = () => {
  const logError = useErrorLogger();
  const isOTPClientPage = useIsOTPClientPage();
  const isPackagesPage = useIsPackagesPage();

  const timerIntervalRef = useRef<ReturnType<typeof setInterval>>();
  const [otpInputDisabled, setOtpInputDisabled] = useState(false);
  const [phoneOrEmailInputError, setPhoneOrEmailInputError] = useState("");
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [otpInputValue, setOtpInputValue] = useState("");
  const [otpTimer, setOtpTimer] = useState(0);

  const {
    query: { medspaSlug, redirectTo },
  } = useRouter();

  const [generateClientPortalOtpMutation] =
    useGenerateClientPortalOtpMutation();

  const [generateClientAccessTokenWithOtpMutation] =
    useGenerateClientAccessTokenWithOtpMutation();

  useEffect(() => {
    if (otpTimer <= 0) {
      clearInterval(timerIntervalRef.current);
    }
  }, [otpTimer]);

  const startTimer = () => {
    setOtpTimer(30);
    timerIntervalRef.current = setInterval(() => {
      setOtpTimer((prev) => prev - 1);
    }, 1000);
    return timerIntervalRef.current;
  };

  const sendClientPhoneOrEmail = async ({
    clientPhone,
    clientEmail,
  }: {
    clientPhone?: string;
    clientEmail?: string;
  }) => {
    try {
      const { data } = await generateClientPortalOtpMutation({
        variables: {
          medspaSlug: medspaSlug as string,
          clientPhone,
          clientEmail,
        },
      });
      if (data.generateClientPortalOtp.ok) {
        toast.success(
          `Code sent to your ${clientPhone ? "phone" : "email"} successfully.`
        );
        setClientPhone(clientPhone);
        setClientEmail(clientEmail);
        setPhoneOrEmailInputError("");
        startTimer();
      }
    } catch (e) {
      logError(e);
      setPhoneOrEmailInputError(e.message);
      toast.error(handleOtpMutationError(e));
    }
  };
  const {
    handleAction: sendClientPhoneOrEmailAction,
    isLoading: isSendClientPhoneOrEmailLoading,
  } = useAction(sendClientPhoneOrEmail);

  const verifyOtpCode = async (code: string) => {
    setOtpInputDisabled(true);
    try {
      const { data } = await generateClientAccessTokenWithOtpMutation({
        variables: {
          medspaSlug: medspaSlug as string,
          clientPhone,
          clientEmail,
          code,
        },
      });

      const { clientAccessToken } = data.generateClientAccessTokenWithOtp;

      if (clientAccessToken) {
        toast.success("Code verified successfully.");
        setCookieWithHoursOfExpiration(
          CLIENT_ACCESS_TOKEN_COOKIE_KEY,
          clientAccessToken,
          HOURS_OF_INACTIVITY_BEFORE_EXPIRATION
        );
        /**
         * Using this method instead of next/router.push to force a full page reload
         * so the provider/context can be re-initialized
         */
        if (redirectTo) {
          window.location.href = redirectTo as string;
        } else {
          if (isOTPClientPage) {
            window.location.href = `/client/${medspaSlug}/appointments`;
          }
          if (isPackagesPage) {
            window.location.href = `/packages/${medspaSlug}`;
          }
        }
      }
    } catch (e) {
      setOtpErrorMessage(handleOtpMutationError(e));
      setOtpInputDisabled(false);
      logError(e.message);
      setOtpInputValue("");
    }
  };

  const onOtpInputChange = (value: string) => {
    setOtpInputValue(value);
    setOtpErrorMessage("");
    if (value.trim().length === OTP_VALUE_LENGTH) {
      verifyOtpCode(value);
    }
  };

  const sendNewOtpCode = () => {
    sendClientPhoneOrEmail({ clientPhone, clientEmail });
  };

  const reset = () => {
    setClientPhone("");
    setClientEmail("");
    setPhoneOrEmailInputError("");
    setOtpInputValue("");
    setOtpErrorMessage("");
    setOtpInputDisabled(false);
    setOtpTimer(0);
    clearInterval(timerIntervalRef.current);
  };

  return {
    clientPhone,
    clientEmail,
    sendClientPhoneOrEmailAction,
    isSendClientPhoneOrEmailLoading,
    sendNewOtpCode,
    onOtpInputChange,
    otpErrorMessage,
    otpInputDisabled,
    otpInputValue,
    otpTimer,
    phoneOrEmailInputError,
    reset,
  };
};

export default useClientOTP;
