import { PROVIDER_SUCCESS_EMAIL } from "@/config";

type GetMailToParams = {
  to: string;
  subject: string;
  body: string;
};

function getMailToHref({ to, subject, body }: GetMailToParams) {
  return `mailto:${to}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
}

export function getErrorPageMailto(subject: string) {
  return getMailToHref({
    to: PROVIDER_SUCCESS_EMAIL,
    subject,
    body: `Page url: ${
      typeof window !== "undefined" && document.URL
    }\n\n ----- \n\n Please describe the issue you are having: \n\n`,
  });
}
