import { useRouter } from "next/router";
import { useUser } from "@/auth/useUser";
import { HASURA_CLIENT_TOKEN_KEY } from "@/config";
import { useClientAccessToken } from "@/contexts/clientAccessTokenContext";
import { useMedSpaFeatureFlagsQuery } from "@/graphql/queries/medspaFeatureFlags.graphql.types";
import { useMedspaFeatureFlagsByAppointmentAccessTokenQuery } from "@/graphql/queries/medspaFeatureFlagsByAppointmentAccessToken.graphql.types";
import { useMedspaFeatureFlagsByClientAccessTokenQuery } from "@/graphql/queries/medspaFeatureFlagsByClientAccessToken.graphql.types";
import { FeatureFlags } from "@/utils/featureFlags";
import { getStripeIdsFromUserMedspa } from "@/utils/stripe";

// WARNING: DO NOT USE ON THE PUBLIC BOOKING PAGE - USE `medspaInfo.additionalPublicInfo.enabledFeatures` INSTEAD
export default function useFeatureFlags() {
  const {
    query: { appointmentAccessToken },
  } = useRouter();
  const { medspa, additionalUserDetails } = useUser();

  const { clientAccessToken: token } = useClientAccessToken();

  const { stripeFinancialAccountId } =
    getStripeIdsFromUserMedspa(additionalUserDetails?.userMedspa, medspa) || {};
  const { data } = useMedSpaFeatureFlagsQuery({
    variables: {
      medspaId: medspa,
    },
    skip: !medspa,
  });

  const { data: clientAccessTokenData } =
    useMedspaFeatureFlagsByClientAccessTokenQuery({
      variables: {
        token: token as string,
      },
      skip: !token,
      context: {
        headers: {
          [HASURA_CLIENT_TOKEN_KEY]: token,
        },
      },
    });

  const { data: appointmentAccessTokenData } =
    useMedspaFeatureFlagsByAppointmentAccessTokenQuery({
      variables: {
        token: appointmentAccessToken as string,
      },
      skip: !appointmentAccessToken,
    });

  const { enabledFeatures = [] } =
    data?.medspaPublicAdditionalInfo ||
    clientAccessTokenData?.clientAccessTokenByPk?.client.medspa
      .additionalPublicInfo ||
    appointmentAccessTokenData?.appointmentPublicInfo.medspa ||
    {};

  return {
    // return all `enabledFeatures` so that is can used more directly my components
    enabledFeatures,
    // boolean to check if feature flags are still loading from BE
    featureFlagsLoading:
      !data && !clientAccessTokenData && !appointmentAccessTokenData,
    // below are specific feature flags making it easy to destructure in components and check if a feature is enabled
    paymentsEnabled: enabledFeatures.includes(FeatureFlags.PAYMENTS_V1),
    balanceEnabled:
      enabledFeatures.includes(FeatureFlags.BALANCE_V1) &&
      !!stripeFinancialAccountId,
    cherryPaymentsEnabled: enabledFeatures.includes(
      FeatureFlags.CHERRY_PAYMENTS_V1
    ),
    packagesAllowRetailProducts: enabledFeatures.includes(
      FeatureFlags.PACKAGES_ALLOW_RETAIL_PRODUCTS
    ),
    messagingMaintenance: enabledFeatures.includes(
      FeatureFlags.MESSAGING_MAINTENANCE
    ),
    standingOrdersV1Enabled: enabledFeatures.includes(
      FeatureFlags.STANDING_ORDERS_V1
    ),
    discountsV4Enabled: enabledFeatures.includes(FeatureFlags.DISCOUNTS_V4),
    affirmPaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.AFFIRM_PAYMENT_METHOD
    ),
    affirmTextClientFlowEnabled: enabledFeatures.includes(
      FeatureFlags.AFFIRM_TEXT_CLIENT_FLOW
    ),
    venmoPaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.VENMO_PAYMENT_METHOD
    ),
    zellePaymentMethodEnabled: enabledFeatures.includes(
      FeatureFlags.ZELLE_PAYMENT_METHOD
    ),
    summaryWalletCreditsEnabledV1: enabledFeatures.includes(
      FeatureFlags.SUMMARY_WALLET_CREDITS_V1
    ),
    newGfeFlowV1: enabledFeatures.includes(FeatureFlags.NEW_GFE_FLOW_V1),
    newGfeFlowV2: enabledFeatures.includes(FeatureFlags.NEW_GFE_FLOW_V2),
    intakeFormServiceTypesV1Enabled: enabledFeatures.includes(
      FeatureFlags.INTAKE_FORM_SERVICE_TYPES_V1
    ),
    weekMonthCalendarEnabled: enabledFeatures.includes(
      FeatureFlags.WEEK_MONTH_CALENDAR
    ),
    clientPortalOtpLoginEnabled: enabledFeatures.includes(
      FeatureFlags.CLIENT_PORTAL_OTP_LOGIN
    ),
    timekitSunsetV1Enabled: enabledFeatures.includes(
      FeatureFlags.TIMEKIT_SUNSET_V1
    ),
    resourceManagementV2Enabled: enabledFeatures.includes(
      FeatureFlags.RESOURCES_MANAGEMENT_V2
    ),
    isMaintenanceScheduled: enabledFeatures.includes(
      FeatureFlags.IS_IN_MAINTENANCE
    ),
    isBookingCustomizationEnabled: enabledFeatures.includes(
      FeatureFlags.BOOKING_CUSTOMIZATION_V1
    ),
    isOnlineStoreMembershipsV1Enabled: enabledFeatures.includes(
      FeatureFlags.ONLINE_STORE_MEMBERSHIPS_V1
    ),
    providerLateCancellationEnabled: enabledFeatures.includes(
      FeatureFlags.PROVIDER_LATE_CANCELLATION
    ),
    consentFormInEveryAppt: enabledFeatures.includes(
      FeatureFlags.CONSENT_FORM_IN_EVERY_APPT
    ),
    clientReschedulingEnabled: enabledFeatures.includes(
      FeatureFlags.CLIENT_RESCHEDULING
    ),
    providerPermissionsV1: enabledFeatures.includes(
      FeatureFlags.PROVIDER_PERMISSIONS_V1
    ),
    layerBookkeepingV1Enabled: enabledFeatures.includes(
      FeatureFlags.LAYER_BOOKKEEPING_v1
    ),
    chartsOverdueV1Enabled: enabledFeatures.includes(
      FeatureFlags.CHARTS_OVERDUE_V1
    ),
    protocolsV2Enabled: enabledFeatures.includes(FeatureFlags.PROTOCOLS_V2),
    calendarEventCreationEnabled: enabledFeatures.includes(
      FeatureFlags.CALENDAR_EVENT_CREATION
    ),
  };
}
