import { Box, Container, SxProps, Theme } from "@mui/material";
import { ReactNode } from "react";
import { useUser } from "@/auth/useUser";
import VerticalNav from "@/components/common/navigation/verticalNav";
import { GREY } from "@/config/mui/colorPalette";
import { useBoolean } from "@/hooks/common/useBoolean";
import useFeatureFlags from "@/hooks/common/useFeatureFlags";
import { IMPERSONATION_BAR_HEIGHT } from "../navigation/impersonationBar";
import { MAINTENANCE_BANNER_HEIGHT } from "../navigation/maintenanceBanner";
import TopBar, { TOP_BAR_HEIGHT } from "../navigation/topBar";

export default function PageContainer({
  children,
  sx = {},
  isFullWidthContent = false,
}: {
  children: ReactNode;
  sx?: SxProps<Theme>;
  isFullWidthContent?: boolean;
}) {
  const { user } = useUser();
  const isImpersonating = !!user?.impersonatedId;
  const nav = useBoolean(false);
  const { isMaintenanceScheduled } = useFeatureFlags();

  return (
    <Box
      sx={{
        minHeight: 1,
        display: "flex",
        flexDirection: "row",
        height: "100dvh",
        bgcolor: GREY[10],
        alignItems: "stretch",
        pt: 0,
        overscrollBehaviorY: "none",
      }}
    >
      {/* Left side */}
      {user && <VerticalNav openNav={nav.value} onCloseNav={nav.onFalse} />}
      {/* Right side */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          bgcolor: GREY[10],
          position: "relative",
          mt: `calc(${user ? TOP_BAR_HEIGHT : "0px"} + ${
            isImpersonating ? IMPERSONATION_BAR_HEIGHT : "0px"
          } + ${isMaintenanceScheduled ? MAINTENANCE_BANNER_HEIGHT : "0px"})`,
        }}
      >
        {user && (
          <TopBar
            user={user}
            onToggleNav={nav.onToggle}
            isNavOpen={nav.value}
          />
        )}
        {/* Needed for scrollbar to appear on right side */}
        <Box
          sx={{
            flex: 1,
            overflowY: "scroll",
          }}
        >
          {!isFullWidthContent ? (
            <Container
              maxWidth="xl"
              component="main"
              sx={{
                display: "flex",
                flexDirection: "column",
                py: 2,
                bgcolor: GREY[10], // needed here to pass to <PageHeader/> components that inherit background color
                ...sx,
              }}
            >
              {children}
            </Container>
          ) : (
            children
          )}
        </Box>
      </Box>
    </Box>
  );
}
