import { useRollbar } from "@rollbar/react";
import { LogArgument } from "rollbar";

export default function useErrorLogger() {
  const rollbar = useRollbar();

  return (...args: LogArgument[]) => {
    rollbar.error(...args);
    console.error(...args);
  };
}
