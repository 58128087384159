export const OTPErrors = {
  MULTIPLE_CLIENTS_EXITS: "multiple-clients-exist",
  TOO_MANY_ATTEMPTS: "too-many-attempts",
  INCORRECT_CODE: "incorrect-code",
  CODE_EXPIRED: "code-expired",
  CLIENT_NOT_FOUND: "client-not-found",
  INACTIVE_CLIENT: "inactive-client",
  MEDSPA_NOT_FOUND: "medspa-not-found",
} as const;

type OtpMutationError = {
  message: string;
};

export const handleOtpMutationError = ({
  message,
}: OtpMutationError): string => {
  switch (message) {
    case OTPErrors.INCORRECT_CODE:
      return "The code you've entered is incorrect, please double check the code we sent and try again. If you didn't receive a code, click below to request a new one.";
    case OTPErrors.CODE_EXPIRED:
      return "The code you've entered has expired. Please use the button below to send new code.";
    case OTPErrors.CLIENT_NOT_FOUND:
      return "Unable to find account. Please contact support team.";
    case OTPErrors.TOO_MANY_ATTEMPTS:
      return "Too many attempts. Please try again in 30 seconds.";
    case OTPErrors.INACTIVE_CLIENT:
      return "We are sorry you are unable to book at this time. Please contact the provider for more information.";
    case OTPErrors.MULTIPLE_CLIENTS_EXITS:
      return "Multiple clients exist with this information. Please contact support for assistance.";
    case OTPErrors.MEDSPA_NOT_FOUND:
      return "The specified medspa could not be found. Please verify the details and try again.";
    default:
      return "An unknown error occurred. Please try again.";
  }
};

export default handleOtpMutationError;
