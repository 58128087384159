import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as Types from "../../__generated__/schema.graphql.types";

const defaultOptions = {} as const;
export type GenerateClientPortalOtpMutationVariables = Types.Exact<{
  medspaSlug: Types.Scalars["String"]["input"];
  clientPhone?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  clientEmail?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type GenerateClientPortalOtpMutation = {
  __typename?: "mutation_root";
  generateClientPortalOtp?: {
    __typename?: "GenerateClientPortalOTP";
    message?: string | null;
    ok?: boolean | null;
  } | null;
};

export const GenerateClientPortalOtpDocument = gql`
  mutation GenerateClientPortalOtp(
    $medspaSlug: String!
    $clientPhone: String
    $clientEmail: String
  ) {
    generateClientPortalOtp(
      medspaSlug: $medspaSlug
      clientPhone: $clientPhone
      clientEmail: $clientEmail
    ) {
      message
      ok
    }
  }
`;
export type GenerateClientPortalOtpMutationFn = Apollo.MutationFunction<
  GenerateClientPortalOtpMutation,
  GenerateClientPortalOtpMutationVariables
>;

/**
 * __useGenerateClientPortalOtpMutation__
 *
 * To run a mutation, you first call `useGenerateClientPortalOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateClientPortalOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateClientPortalOtpMutation, { data, loading, error }] = useGenerateClientPortalOtpMutation({
 *   variables: {
 *      medspaSlug: // value for 'medspaSlug'
 *      clientPhone: // value for 'clientPhone'
 *      clientEmail: // value for 'clientEmail'
 *   },
 * });
 */
export function useGenerateClientPortalOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateClientPortalOtpMutation,
    GenerateClientPortalOtpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateClientPortalOtpMutation,
    GenerateClientPortalOtpMutationVariables
  >(GenerateClientPortalOtpDocument, options);
}
export type GenerateClientPortalOtpMutationHookResult = ReturnType<
  typeof useGenerateClientPortalOtpMutation
>;
export type GenerateClientPortalOtpMutationResult =
  Apollo.MutationResult<GenerateClientPortalOtpMutation>;
export type GenerateClientPortalOtpMutationOptions = Apollo.BaseMutationOptions<
  GenerateClientPortalOtpMutation,
  GenerateClientPortalOtpMutationVariables
>;
