import {
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import SlideUpTransition from "@/components/common/modals/slideUpTransition";
import { CrossIcon } from "../icons";

export type OptionModalProps = {
  onClose: () => void;
  open: boolean;
  title: string;
  onSelectAll?: () => void;
  selectAllTitle?: string;
};

type Props = OptionModalProps & {
  onSubmit: () => void;
  children: ReactNode | ReactNode[];
  buttonLabel: string;
  isDisabled?: boolean;
};

export default function OptionsModal({
  onClose,
  open,
  title,
  onSubmit,
  children,
  buttonLabel,
  isDisabled = false,
  onSelectAll,
  selectAllTitle,
}: Props) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          margin: 0,
          width: "100%",
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
        },
      }}
      sx={{
        "& .MuiDialog-container": {
          justifyContent: "center",
          alignItems: "flex-end",
        },
      }}
      TransitionComponent={SlideUpTransition}
    >
      <Container
        sx={{
          height: "auto",
          pt: 3,
        }}
      >
        <Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <IconButton onClick={onClose} sx={{ ml: -1 }}>
              <CrossIcon color="#131316" />
            </IconButton>
            <Typography
              textAlign="center"
              variant="h2"
              ml={onSelectAll ? 4 : 2}
            >
              {title}
            </Typography>

            {onSelectAll ? (
              <SelectAllButton label={selectAllTitle} onClick={onSelectAll} />
            ) : (
              <Box width={32} />
            )}
          </Box>
          <FormControl>{children}</FormControl>
          <Button
            variant="contained"
            sx={{ my: 3 }}
            onClick={onSubmit}
            disabled={isDisabled}
          >
            {buttonLabel}
          </Button>
        </Stack>
      </Container>
    </Dialog>
  );
}

export const SelectAllButton = ({
  label,
  onClick,
  disabled,
}: {
  label: string;
  onClick: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      variant="text"
      onClick={onClick}
      disabled={disabled}
      sx={{ p: 0, width: "85px" }}
    >
      <Typography variant="subtitleSmall">{label}</Typography>
    </Button>
  );
};
