import { GetToken } from "@clerk/types";
import { useContext } from "react";
import liveSessionIdentify from "@/config/analytics/liveSessionIdentify";
import { UserQuery } from "@/graphql/queries/user.graphql.types";
import {
  CLERK_JWT_HASURA_TEMPLATE,
  HASURA_CLAIMS,
  HASURA_DEFAULT_ROLE,
  HASURA_IMPERSONATED_ID,
  HASURA_IMPERSONATOR_ID,
  HASURA_USER_EMAIL,
  HASURA_USER_ID,
  HasuraClaimsType,
  Role,
} from "@/types";
import { UserAuthContext } from "./userAuthContext";

export type AuthUser =
  | {
      id: string;
      email: string;
      role: Role;
      displayName?: string;
      impersonatedId?: string;
      impersonatorId?: string;
    }
  | undefined;

export type UserMedspa = UserQuery["userMedspa"][number];

export type UseUserResult = {
  user: AuthUser | undefined;
  loading: boolean;
  medspa: string;
  additionalUserDetails: UserQuery;
  providerUserMedspa?: UserMedspa;
  userMedspa?: UserMedspa;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const decodeToken = (token: string): any => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const getRole = (token: string): Role => {
  return decodeToken(token)[HASURA_CLAIMS][HASURA_DEFAULT_ROLE];
};

export const mapUserData = async (getToken: GetToken) => {
  const token = await getToken(CLERK_JWT_HASURA_TEMPLATE);
  const decodedToken = decodeToken(token);

  const hasuraClaims: HasuraClaimsType = decodedToken[HASURA_CLAIMS];

  const user: AuthUser = {
    id: hasuraClaims[HASURA_USER_ID],
    email: hasuraClaims[HASURA_USER_EMAIL],
    role: hasuraClaims[HASURA_DEFAULT_ROLE],
    impersonatedId: hasuraClaims[HASURA_IMPERSONATED_ID],
    impersonatorId: hasuraClaims[HASURA_IMPERSONATOR_ID],
  };

  liveSessionIdentify(user, hasuraClaims);

  return user;
};
// helper function to convert Role strings into human readable format
export const humanize = (role: Role | string): string => {
  const frags = role.split("_");
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
};

export const hasRole = (user: AuthUser, roles: Role[]) => {
  return roles.includes(user?.role);
};

const useUser = (): UseUserResult => {
  const context = useContext(UserAuthContext);
  if (!context) {
    throw new Error("Must have proper ContextProvider defined in parent tree.");
  }
  return context;
};

export { useUser };
