import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import { MOXIE_HOME_PAGE_URL } from "@/config";

type Props = {
  title: string;
  description: ReactNode;
  contactProviderHref?: string;
  showContactProviderButton?: boolean;
  showLoginButtons?: boolean;
  showSpinner?: boolean;
  showActionButtons?: boolean;
  customActionButtons?: ReactNode;
};

const ErrorMessage = ({
  title,
  description,
  contactProviderHref,
  showContactProviderButton = false,
  showLoginButtons = false,
  showSpinner = false,
  showActionButtons = true,
  customActionButtons,
}: Props) => {
  const { reload } = useRouter();

  return (
    <Stack gap={2} alignItems="center" height="100%" justifyContent="center">
      <Typography variant="h1" textAlign="center">
        {title}
      </Typography>
      <Typography component="div" textAlign="center" maxWidth="600px">
        {description}
      </Typography>

      {showActionButtons && (
        <Stack display="flex" gap={2}>
          <Button variant="contained" onClick={reload}>
            Refresh page
          </Button>
          {showContactProviderButton && contactProviderHref && (
            <Button variant="outlined" href={contactProviderHref}>
              Contact Provider Success
            </Button>
          )}
        </Stack>
      )}

      {showLoginButtons && (
        <>
          <Button variant="contained" href="/sign-in">
            Log in
          </Button>
          <Button variant="contained" href={MOXIE_HOME_PAGE_URL}>
            Moxie Home Page
          </Button>
        </>
      )}
      {customActionButtons && <Stack gap={2}>{customActionButtons}</Stack>}
      {showSpinner && <CircularProgress size="48px" color="secondary" />}
    </Stack>
  );
};

export function FullPageError({ children }: { children: ReactNode }) {
  return (
    <Stack justifyContent="center" sx={{ minHeight: "90vh", p: 1 }}>
      {children}
    </Stack>
  );
}

export default ErrorMessage;
