const clerkProviderProps = {
  appearance: {
    variables: {
      colorPrimary: "rgb(138, 29, 150)",
      colorTextOnPrimaryBackground: "white",
    },

    elements: {
      profileSectionPrimaryButton__emailAddresses: {
        display: "none",
      },
    },
  },
};

export default clerkProviderProps;
