import { Box } from "@mui/material";
import { useEffect } from "react";
import { PRIMARY } from "@/config/mui/colorPalette";
import { useMessagesContext } from "@/contexts/messagesContext";

type Props = { floating?: boolean };

export default function MessagesNotificationsDot({ floating = true }: Props) {
  const {
    conversations,
    lastMessageSid,
    calculateNotificationsCount,
    notificationsCount,
  } = useMessagesContext();

  useEffect(() => {
    if (conversations.length) {
      calculateNotificationsCount();
    }
  }, [conversations, lastMessageSid]);

  useEffect(() => {
    window.addEventListener("focus", calculateNotificationsCount);
    return () =>
      window.removeEventListener("focus", calculateNotificationsCount);
  }, []);

  if (!notificationsCount) return;

  const floatingStyles = {
    position: "absolute",
    top: -5,
    right: -5,
  };

  return (
    <Box
      sx={{
        ...(floating ? floatingStyles : {}),
        width: "16px",
        height: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        bgcolor: PRIMARY,
        color: "white",
        typography: "labelTiny",
      }}
    >
      {notificationsCount}
    </Box>
  );
}
