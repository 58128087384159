import { IS_DEVELOPMENT } from "@/config";

// Medspa scoped feature flags (TODO, migrate the remaining global flags to this const)
export const FeatureFlags = {
  PAYMENTS_V1: "payments-v1",
  BALANCE_V1: "balance-v1",
  CHERRY_PAYMENTS_V1: "cherry-payments-v1",
  PACKAGES_ALLOW_RETAIL_PRODUCTS: "packages-allow-retail-products",
  MESSAGING_MAINTENANCE: "messaging-maintenance-v1",
  XPERIENCE_WALLET_TYPE: "xperience-wallet-type",
  STANDING_ORDERS_V1: "standing-orders-v1",
  DISCOUNTS_V4: "discounts-v4",
  AFFIRM_PAYMENT_METHOD: "affirm-payment-method",
  AFFIRM_TEXT_CLIENT_FLOW: "affirm-text-client-flow",
  SUMMARY_WALLET_CREDITS_V1: "summary-wallet-credits-v1",
  NEW_GFE_FLOW_V1: "new-gfe-flow-v1",
  NEW_GFE_FLOW_V2: "new-gfe-flow-v2",
  VENMO_PAYMENT_METHOD: "venmo-payment-method",
  ZELLE_PAYMENT_METHOD: "zelle-payment-method",
  INTAKE_FORM_SERVICE_TYPES_V1: "intake-form-service-types-v1",
  WEEK_MONTH_CALENDAR: "week-month-calendar",
  CLIENT_PORTAL_OTP_LOGIN: "client-portal-otp-login",
  TIMEKIT_SUNSET_V1: "timekit-sunset-v1",
  RESOURCES_MANAGEMENT_V2: "resources-management-v2",
  IS_IN_MAINTENANCE: "maintenance-banner-v1",
  BOOKING_CUSTOMIZATION_V1: "booking-customization-v1",
  ONLINE_STORE_MEMBERSHIPS_V1: "online-store-memberships-v1",
  PROVIDER_LATE_CANCELLATION: "provider-late-cancellation",
  CONSENT_FORM_IN_EVERY_APPT: "consent-form-in-every-appt",
  CLIENT_RESCHEDULING: "client-rescheduling",
  PROVIDER_PERMISSIONS_V1: "provider-permissions-v1",
  LAYER_BOOKKEEPING_v1: "layer-bookkeeping-v1",
  CHARTS_OVERDUE_V1: "charts-overdue-v1",
  PROTOCOLS_V2: "protocols-v2",
  CALENDAR_EVENT_CREATION: "calendar-event-creation",
} as const;

export type FeatureFlag = (typeof FeatureFlags)[keyof typeof FeatureFlags];

// Global feature flags
const FLAGS = {
  googleCalendarSync: IS_DEVELOPMENT,
} as const;

type Feature = keyof typeof FLAGS;

const isFeatureEnabled = (feature: Feature) => FLAGS[feature];
export const googleCalendarSyncEnabled = isFeatureEnabled("googleCalendarSync");
