import {
  AMBER,
  BLOODY,
  BLUE,
  CYAN,
  DARK_PURPLE,
  DEEP_ORANGE,
  GOLD,
  GREEN,
  INDIGO,
  LIGHT_BLUE,
  MINT,
  OCEAN_BLUE,
  PINK,
  PURPLE,
  SALMON,
  TEAL,
  YELLOW,
} from "./mui/colorPalette";

// START ENVIRONMENTS
export const ENVIRONMENTS = {
  LOCAL: "local",
  QA: `qa`,
  STAGING: `staging`,
  PRODUCTION: `production`,
} as const;

export type ENVIRONMENT = (typeof ENVIRONMENTS)[keyof typeof ENVIRONMENTS];

export const PUBLIC_ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENV || ENVIRONMENTS.LOCAL;

export const IS_DEVELOPMENT = {
  [ENVIRONMENTS.LOCAL]: true,
  [ENVIRONMENTS.QA]: true,
  [ENVIRONMENTS.STAGING]: true,
  [ENVIRONMENTS.PRODUCTION]: false,
}[PUBLIC_ENVIRONMENT];

export const IS_PRODUCTION = PUBLIC_ENVIRONMENT === ENVIRONMENTS.PRODUCTION;
// END ENVIRONMENTS

export const DATE_FORMATS = {
  DATE_PICKER: "MM/dd/yyyy",
  TILE: "EEE, MMM d",
  CALENDAR: "EEE, MMMM d",
  DEFAULT: "PPP",
  SERVER_DATE: "yyyy-MM-dd",
  SHORT_DATE: "PP",
  SHORT_MONTH_DAY: "MMM d",
  SHORT_WEEKDAY: "EEE, d",
};

export const TIME_FORMATS = {
  WORKING_HOURS_TIME_PICKER: "H:mm",
  HOUR_MINUTE_PICKER: "HH:mm",
  BOOKING: "p",
  TILE: "hh:mm aaa",
};

export const DATE_TIME_FORMATS = {
  DEFAULT: "P hh:mm aaa",
  TILE: "PPpp",
  BOOKING: "PPPP",
  SHORT_DATE_TIME: "PP - p",
  SHORT_DATE_LONG_TIME: "eee, MMM d, yyyy h:mm a",
};

export const AVATAR_COLORS = [
  "#C2D6D3",
  "#ADB1EB",
  "#F5F5A3",
  "#F5ABA3",
  "#A3EFF5",
];

export type EventColorPalette = {
  [100]: string;
  [80]: string;
  [40]: string;
  [20]: string;
};

type AvatarMapping = {
  [key: number]: EventColorPalette;
};
export const AVATAR_MAPPING: AvatarMapping = {
  0: GREEN,
  1: BLUE,
  2: SALMON,
  3: YELLOW,
  4: MINT,

  5: PINK,
  6: PURPLE,
  7: OCEAN_BLUE,
  8: BLOODY,
  9: CYAN,
  10: TEAL,
  11: AMBER,
  12: GOLD,
  13: DEEP_ORANGE,
  14: DARK_PURPLE,
  15: LIGHT_BLUE,
  16: INDIGO,
} as const;

export const HYPHEN = "–";

export const TIMEKIT_BASE =
  process.env.NEXT_PUBLIC_TIMEKIT_URI || "https://api.timekit.io/v2";

export const TIMEKIT_PROJECT_ID = process.env.NEXT_PUBLIC_TIMEKIT_PROJECT_ID;

export const APP_URL_BASE = process.env.NEXT_PUBLIC_APP_URL_BASE;

export const MOXIE_HELP_DESK_URL =
  "https://help.joinmoxie.com/knowledge/moxie-suite";

export const FULLCALENDAR_LICENSE_KEY =
  process.env.NEXT_PUBLIC_FULLCALENDAR_LICENSE_KEY;

export const HASURA_CLIENT_TOKEN_KEY = "X-Hasura-Client-Access-Token";

export const PROVIDER_SUCCESS_EMAIL = "providersuccess@joinmoxie.com";

export const MOXIE_HOME_PAGE_URL = "https://joinmoxie.com/";

export const MOXIE_HELP_DESK_2FA =
  "https://help.joinmoxie.com/knowledge/2fa-faq";

export const MOXIE_HELP_DESK_COLOR_CUSTOMIZATION =
  "https://help.joinmoxie.com/knowledge/how-to-customize-your-online-booking-link";
export const DJANGO_ADMIN_STOP_IMPERSONATION_URL = `${process.env.NEXT_PUBLIC_DJANGO_URI}/impersonate/stop/`;

export enum COLLECTIONS {
  ALLERGAN = "allergan",
  GALDERMA = "galderma",
  JEUVEAU = "jeuveau",
  MERZ = "merz",
  COSMOFRANCE = "cosmofrance",
  CROWN_AESTHETICS = "crown_aesthetics",
  REVANCE = "revance",
}

type ShopifyConfig = {
  DOMAIN: string;
  TOKEN: string;
  COLLECTION_IDS: Record<COLLECTIONS, string>;
};

export const SHOPIFY_CONFIG: ShopifyConfig = {
  DOMAIN: "moxie-9681.myshopify.com",
  TOKEN: "5ac639afded3bb1dc06d0a178e6f6b24",
  COLLECTION_IDS: {
    [COLLECTIONS.ALLERGAN]: "464113598754",
    [COLLECTIONS.GALDERMA]: "464113565986",
    [COLLECTIONS.JEUVEAU]: "475501887778",
    [COLLECTIONS.MERZ]: "480449102114",
    [COLLECTIONS.COSMOFRANCE]: "480449364258",
    [COLLECTIONS.CROWN_AESTHETICS]: "480449397026",
    [COLLECTIONS.REVANCE]: "483878633762",
  },
};

export const STRIPE_MIN_PAYMENT_AMOUNT = 0.5;
export const AFFIRM_MIN_PAYMENT_AMOUNT = 50;
export const AFFIRM_MAX_PAYMENT_AMOUNT = 10000;

export const NEXT_PUBLIC_MAINTENANCE_MESSAGE =
  process.env.NEXT_PUBLIC_MAINTENANCE_MESSAGE;
