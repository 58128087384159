import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ConfirmCheckboxOptions,
  OpenConfirmOptions,
} from "@/components/providers/confirmProvider";
import LoadingButton from "../buttons/loadingButton";
import AdditionalCheckbox from "./additionalCheckbox";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  textAlign: "center",
  boxShadow: "0px 4px 15px rgba(56, 19, 60, 0.25)",
  py: 3,
  px: 2,
  borderRadius: "16px",
  maxWidth: "400px",
};

type Props = {
  isConfirmOpen: boolean;
  confirm: (checkboxValue?: boolean) => void;
  discard: (checkboxValue?: boolean) => void;
  confirmOptions: OpenConfirmOptions;
  checkboxOptions?: ConfirmCheckboxOptions;
  additionalContent?: React.ReactNode;
  confirmButtonLoading?: boolean;
};

export default function ConfirmModal({
  isConfirmOpen,
  confirm,
  discard,
  confirmOptions: {
    title,
    description,
    discardButtonText,
    confirmButtonText,
    confirmButtonDisabled,
  },
  checkboxOptions,
  additionalContent,
  confirmButtonLoading = false,
}: Props) {
  const [checkboxValue, setCheckboxValue] = useState<boolean>(
    checkboxOptions?.defaultValue || false
  );
  useEffect(() => {
    if (
      isConfirmOpen &&
      checkboxOptions &&
      checkboxValue !== checkboxOptions.defaultValue
    )
      setCheckboxValue(checkboxOptions?.defaultValue);
  }, [isConfirmOpen]);

  const handleDiscard = () => discard(checkboxValue);
  const handleConfirm = () => confirm(checkboxValue);

  return (
    <Modal sx={{ zIndex: 10000 }} open={isConfirmOpen} disableEscapeKeyDown>
      <Box sx={style}>
        <Typography variant="h2">{title}</Typography>
        {typeof description === "string" ? (
          <Typography sx={{ color: "secondary.main", mt: 2, mb: 4 }}>
            {description}
          </Typography>
        ) : (
          description
        )}

        {additionalContent}
        {checkboxOptions && (
          <AdditionalCheckbox
            label={checkboxOptions.label}
            fieldName="confirm-email-checkbox"
            value={checkboxValue}
            setValue={setCheckboxValue}
          />
        )}
        <Stack
          spacing={1}
          direction="row"
          sx={{ width: "100%" }}
          justifyContent="center"
        >
          {discardButtonText && (
            <Button
              variant="outlined"
              onClick={handleDiscard}
              fullWidth
              sx={{
                borderRadius: "16px",
                py: 2,
                height: "56px",
                minWidth: "140px",
              }}
            >
              {discardButtonText}
            </Button>
          )}
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            fullWidth
            loading={confirmButtonLoading}
            disabled={confirmButtonDisabled}
            sx={{
              borderRadius: "16px",
              py: 2,
              height: "56px",
              minWidth: discardButtonText ? "140px" : "280px",
            }}
          >
            {confirmButtonText}
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}
