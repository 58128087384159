import { IconButton, Stack } from "@mui/material";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import { CrossSmallIcon } from "@/components/common/icons";
import { GREY, WHITE } from "@/config/mui/colorPalette";

export default function MainToaster() {
  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        style: {
          borderRadius: "12px",
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t} position="bottom-center">
          {({ icon, message }) => (
            <Stack direction="row" alignItems="center" justifyContent="center">
              {icon}
              {message}
              {t.type !== "loading" && (
                <IconButton
                  sx={{
                    p: 0,
                    backgroundColor: GREY[50],
                    "&:hover": {
                      backgroundColor: GREY[60],
                    },
                  }}
                  onClick={() => toast.dismiss(t.id)}
                >
                  <CrossSmallIcon color={WHITE} size={20} />
                </IconButton>
              )}
            </Stack>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
