import { useRouter } from "next/router";
import { useEffect } from "react";
import { getErrorPageMailto } from "@/utils/mailto";
import ErrorMessage, { FullPageError } from "./errorMessage";

export default function ErrorBoundaryFallback({
  error,
  resetError, // todo: add a button to reset the error
}: {
  error?: Error;
  resetError?: () => void;
}) {
  const router = useRouter();

  const description = (
    <>
      {error
        ? `The following error has occured: ${error.toString()}`
        : "An unknown error occured."}
      <br />
      Please refresh the page. If the issue persist reach out to our provider
      success team.
    </>
  );

  const contactProviderHref = getErrorPageMailto(
    "Moxie Suite Client Side Error"
  );

  useEffect(
    // We need to reset error boundary state when navigating away from the error screen
    () => {
      router.events.on("routeChangeComplete", resetError);
      router.events.on("routeChangeError", resetError);

      return () => {
        router.events.off("routeChangeComplete", resetError);
        router.events.off("routeChangeError", resetError);
      };
    },
    [router]
  );

  return (
    <FullPageError>
      <ErrorMessage
        title="Client Side Error"
        description={description}
        contactProviderHref={contactProviderHref}
        showContactProviderButton
      />
    </FullPageError>
  );
}
