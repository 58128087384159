import { ClerkProvider } from "@clerk/nextjs";
import { CacheProvider, EmotionCache, ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import {
  ErrorBoundary as ErrorBoundaryRollbar,
  Provider,
} from "@rollbar/react";
import NextAdapterPages from "next-query-params/pages";
import type { AppProps } from "next/app";
import App from "next/app";
import dynamic from "next/dynamic";
import { QueryParamProvider } from "use-query-params";
import { UserAuthContextProvider } from "@/auth/userAuthContext";
import DatadogInit from "@/components/common/datadog/datadogInit";
import ErrorBoundary from "@/components/common/errors/errorBoundary";
import ErrorBoundaryFallback from "@/components/common/errors/errorBoundaryFallback";
import { PWAPullToRefresh } from "@/components/common/navigation/pwaPullToRefresh";
import MainToaster from "@/components/common/toasts/mainToaster";
import ConfirmProvider from "@/components/providers/confirmProvider";
import clerkProviderProps from "@/config/clerk";
import { rollbarConfig } from "@/config/logging/rollbar";
import createEmotionCache from "@/config/mui/createEmotionCache";
import theme from "@/config/mui/theme";
import ClientAccessTokenProvider from "@/contexts/clientAccessTokenContext";
import { MessagesContextProvider } from "@/contexts/messagesContext";
import useMuiMenuDisableScrolling from "@/hooks/misc/useMuiMenuDisableScrolling";
import { ApolloProviderWrapper } from "@/lib/apollo";
import Maintenance from "@/views/maintenance";
import "../styles/globals.css";

const BirdEatsBugWebSDK = dynamic(
  () => import("@/components/common/birdEatsBugWebSDK/birdEatsBugWebSDK"),
  {
    ssr: false,
  }
);

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const localization = {
  userButton: {
    action__addAccount: "Switch account",
  },
};

function MoxieApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const isMaintenanceActive =
    process.env.NEXT_PUBLIC_IS_MAINTENANCE_MODE_ENABLED === "True";

  // TODO: bring back ability to disable navigation on a per-page basis
  // const showNavigation = !!Component?.showNavigation;

  useMuiMenuDisableScrolling();

  // TODO: This is just for DataDog testing on staging purposes and should be deleted soon after tests are done
  if (Component?.useDatadogBoundary) {
    return (
      <Provider config={rollbarConfig}>
        <ErrorBoundary>
          <CacheProvider value={emotionCache}>
            <ClerkProvider
              localization={localization}
              {...pageProps}
              {...clerkProviderProps}
            >
              <ApolloProviderWrapper pageProps={pageProps}>
                <ThemeProvider theme={theme}>
                  <UserAuthContextProvider>
                    <ConfirmProvider>
                      <QueryParamProvider adapter={NextAdapterPages}>
                        <CssBaseline />
                        <MessagesContextProvider>
                          <ErrorBoundary>
                            <PWAPullToRefresh>
                              <ClientAccessTokenProvider>
                                {!isMaintenanceActive ? (
                                  <>
                                    <DatadogInit />
                                    <BirdEatsBugWebSDK />
                                    <Component {...pageProps} />
                                    <MainToaster />
                                  </>
                                ) : (
                                  <Maintenance />
                                )}
                              </ClientAccessTokenProvider>
                            </PWAPullToRefresh>
                          </ErrorBoundary>
                        </MessagesContextProvider>
                      </QueryParamProvider>
                    </ConfirmProvider>
                  </UserAuthContextProvider>
                </ThemeProvider>
              </ApolloProviderWrapper>
            </ClerkProvider>
          </CacheProvider>
        </ErrorBoundary>
      </Provider>
    );
  }

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundaryRollbar fallbackUI={ErrorBoundaryFallback}>
        <CacheProvider value={emotionCache}>
          <ClerkProvider
            localization={localization}
            {...pageProps}
            {...clerkProviderProps}
          >
            <ApolloProviderWrapper pageProps={pageProps}>
              <ThemeProvider theme={theme}>
                <UserAuthContextProvider>
                  <ConfirmProvider>
                    <QueryParamProvider adapter={NextAdapterPages}>
                      <CssBaseline />
                      <MessagesContextProvider>
                        <ErrorBoundaryRollbar
                          fallbackUI={ErrorBoundaryFallback}
                        >
                          <PWAPullToRefresh>
                            <ClientAccessTokenProvider>
                              {!isMaintenanceActive ? (
                                <>
                                  <DatadogInit />
                                  <BirdEatsBugWebSDK />
                                  <Component {...pageProps} />
                                  <MainToaster />
                                </>
                              ) : (
                                <Maintenance />
                              )}
                            </ClientAccessTokenProvider>
                          </PWAPullToRefresh>
                        </ErrorBoundaryRollbar>
                      </MessagesContextProvider>
                    </QueryParamProvider>
                  </ConfirmProvider>
                </UserAuthContextProvider>
              </ThemeProvider>
            </ApolloProviderWrapper>
          </ClerkProvider>
        </CacheProvider>
      </ErrorBoundaryRollbar>
    </Provider>
  );
}
export default MoxieApp;

MoxieApp.getInitialProps = async (appContext) => {
  // This is a workaround to fully disable Automatic Static Optimization on ALL PAGES
  // Learn more about Automatic Static Optimization here: https://nextjs.org/docs/pages/building-your-application/rendering/automatic-static-optimization
  // We don't really need ASO for Moxie, as most of our pages are dynamic and behind a login wall
  // We may want ASO for some pages in the future, for example improving the load times for the booking/online store pages
  const appProps = await App.getInitialProps(appContext);
  return appProps;
};
