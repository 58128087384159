import {
  ConnectionState,
  Client,
  Message,
  Conversation,
} from "@twilio/conversations";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { OrderBy } from "@/__generated__/schema.graphql.types";
import { hasRole, useUser } from "@/auth/useUser";
import {
  ClientsQuery,
  useClientsQuery,
} from "@/graphql/queries/clients.graphql.types";
import { useTwilioTokenQuery } from "@/graphql/queries/twilioToken.graphql.types";
import useJoinConversation from "@/hooks/messages/useJoinConversation";
import useRecordLongLoading from "@/hooks/messages/useRecordLongLoading";
import { PROVIDER } from "@/types";
import {
  ConversationData,
  getMutipleRequestsError,
  getNotificationsCount,
  getSubscribedConversations,
  isErrorConnectionState,
} from "@/utils/messages";
import useErrorLogger from "@/utils/useErrorLogger";

// this type is not exported by twilio we need to recreate it ourselves :(
export type ConnectionError = {
  terminal: boolean;
  message: string;
};

export type ClientState =
  | {
      state: "loading";
      isLongLoading: boolean;
      connectionState: ConnectionState;
    }
  | {
      state: "ready";
      connectionState: ConnectionState;
    }
  | {
      state: "error";
      connectionState: ConnectionState;
      customErrorMessage?: string;
      initFailedError?: ConnectionError;
    };

const defaultClientState: ClientState = {
  state: "loading",
  isLongLoading: false,
  connectionState: "connecting",
};

export type ClientType = ClientsQuery["client"][number];

type MessagesContextType = {
  conversations: Conversation[];
  conversationsData: Map<string, ConversationData>;
  clients: ClientType[];
  clientState: ClientState;
  lastMessageSid: string;
  notificationsCount: number;
  calculateNotificationsCount: () => void;
  joinConversation: (sid: string) => Promise<void>;
  leaveConversation: (sid: string) => Promise<void>;
};

function sortConversations(conversations: Conversation[]) {
  conversations.sort((a, b) => {
    const aDate = a.lastMessage?.dateCreated;
    const bDate = b.lastMessage?.dateCreated;
    if (!aDate && !bDate) return 0;
    if (!aDate) return 1;
    if (!bDate) return -1;
    return +bDate - +aDate;
  });
}

export const MessagesContext = createContext<MessagesContextType>(null);

export const useMessagesContextState = () => {
  const logError = useErrorLogger();
  const twilioClient = useRef<Client>(null);

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [lastMessageSid, setLastMessageSid] = useState("");
  const [clientState, setClientState] =
    useState<ClientState>(defaultClientState);
  const [notificationsCount, setNotificationsCount] = useState(0);

  const { medspa, user } = useUser();

  const { data: clientsData } = useClientsQuery({
    variables: {
      orderBy: [{ firstName: OrderBy.Asc }],
      medspaId: medspa,
    },
    skip: !user || !medspa,
    fetchPolicy: "network-only",
  });
  const clientsLoaded = !!clientsData;

  const {
    data: twilioData,
    refetch: refreshToken,
    previousData: previousTwilioData,
  } = useTwilioTokenQuery({
    variables: {
      id: medspa,
    },
    skip: !medspa || !user || !hasRole(user, [PROVIDER]),
  });

  const twilioToken =
    twilioData?.medspaByPk?.additionalInfo?.twilioConversationsToken;
  const oldTwilioToken =
    previousTwilioData?.medspaByPk?.additionalInfo?.twilioConversationsToken;
  const isStaleToken = twilioToken === oldTwilioToken;

  useEffect(() => {
    if (!twilioToken || !clientsLoaded) return;
    if (isStaleToken) {
      refreshToken();
      return;
    }
    if (twilioClient.current) {
      twilioClient.current.updateToken(twilioToken);
      return;
    }
    initConversations(twilioToken);
  }, [twilioToken, clientsLoaded, isStaleToken]);

  useEffect(() => {
    // user logged out
    if (!user && twilioClient.current) {
      const clearConversationsState = () => {
        twilioClient.current.removeAllListeners();
        twilioClient.current.shutdown();
        twilioClient.current = null;
        setConversations([]);
        setLastMessageSid("");
        setClientState(defaultClientState);
      };

      clearConversationsState();
    }
  }, [user]);

  const { registerStartLoading, registerFinishLoading } = useRecordLongLoading(
    () =>
      setClientState((state) =>
        state.state === "loading"
          ? {
              ...state,
              isLongLoading: true,
            }
          : state
      )
  );

  const initConversations = async (token: string) => {
    const conversationsClient = new Client(token);
    registerStartLoading();

    conversationsClient.on("connectionStateChanged", (connectionState) => {
      if (isErrorConnectionState(connectionState)) {
        setClientState({
          state: "error",
          connectionState,
        });
        logError(
          new Error(`Twilio client connection state is ${connectionState}`)
        );
        return;
      }

      setClientState((state) =>
        state.state === "loading"
          ? {
              ...state,
              connectionState,
            }
          : { state: "ready", connectionState }
      );
    });

    conversationsClient.on("initFailed", (event) => {
      registerFinishLoading();

      logError(
        new Error(
          `Twilio client initFailed. ${
            event.error && JSON.stringify(event.error)
          }`
        )
      );
      setClientState({
        state: "error",
        initFailedError: event.error,
        connectionState: conversationsClient.connectionState,
      });
    });

    conversationsClient.on("initialized", async () => {
      try {
        const conversations =
          await getSubscribedConversations(conversationsClient);
        sortConversations(conversations);
        setConversations(conversations);
        setClientState({
          state: "ready",
          connectionState: conversationsClient.connectionState,
        });
      } catch (cause) {
        logError(
          new Error("getSubscribedConversations() failed", {
            cause,
          })
        );
        setClientState({
          state: "error",
          connectionState: conversationsClient.connectionState,
          customErrorMessage: "Could not load conversations.",
        });
      } finally {
        registerFinishLoading();
      }
    });

    conversationsClient.on("conversationJoined", (conversation) => {
      setConversations((prev) => prev.concat(conversation));
    });

    conversationsClient.on("conversationLeft", (thisConversation) => {
      setConversations((prev) => prev.filter((it) => it !== thisConversation));
    });

    conversationsClient.on("messageAdded", async (message: Message) => {
      try {
        const conversations =
          await getSubscribedConversations(conversationsClient);

        if (conversations.length) {
          sortConversations(conversations);
          setConversations(conversations);
          setLastMessageSid(message.sid);
        }
      } catch (cause) {
        logError(
          new Error('on "messageAdded" getSubscribedConversations() failed', {
            cause,
          })
        );
      }
    });

    conversationsClient.on("tokenAboutToExpire", () => refreshToken());
    conversationsClient.on("tokenExpired", () => refreshToken());
    // after all events registered, maintain same client and only update token value
    twilioClient.current = conversationsClient;
  };

  const [conversationsData, setConversationsData] = useState<
    Map<string, ConversationData>
  >(new Map());

  const appendConversationsData = (sid: string, data: ConversationData) => {
    setConversationsData((prevConversationsData) => {
      const newConversationsData = new Map(prevConversationsData);

      if (newConversationsData.has(sid)) {
        newConversationsData.set(sid, {
          ...newConversationsData.get(sid),
          ...data,
        });
      } else {
        newConversationsData.set(sid, data);
      }

      return newConversationsData;
    });
  };

  const isCalculatingNotificationsCount = useRef(false);

  const calculateNotificationsCount = async () => {
    if (!conversations.length || isCalculatingNotificationsCount.current)
      return;

    isCalculatingNotificationsCount.current = true;
    const {
      notificationsCount,
      getLastMessageErrorsCount,
      getUnreadMessagesCountErrorsCount,
    } = await getNotificationsCount(conversations, appendConversationsData);
    isCalculatingNotificationsCount.current = false;

    if (getLastMessageErrorsCount || getUnreadMessagesCountErrorsCount) {
      logError(
        getMutipleRequestsError(
          "getNotificationsCount()",
          getLastMessageErrorsCount,
          getUnreadMessagesCountErrorsCount
        )
      );
    }

    setNotificationsCount(notificationsCount);
  };

  const { joinConversation, leaveConversation } = useJoinConversation();

  return useMemo(
    () => ({
      conversations,
      conversationsData,
      clients: clientsData?.client,
      clientState,
      lastMessageSid,
      calculateNotificationsCount,
      notificationsCount,
      joinConversation,
      leaveConversation,
    }),
    [
      conversations,
      conversationsData,
      clientsData,
      clientState,
      lastMessageSid,
      calculateNotificationsCount,
      notificationsCount,
    ]
  );
};

export const MessagesContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <MessagesContext.Provider value={useMessagesContextState()}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = (): MessagesContextType => {
  const context = useContext(MessagesContext);

  if (!context) {
    throw new Error("Must be used inside a MessageContextProvider");
  }

  return context;
};
