import { useState } from "react";

type Options<Args, ReturnValue> = {
  onSuccess?: (args: Args, value: ReturnValue) => void;
  onError?: (args: Args, err: Error) => void;
};

export default function useAction<Args extends Array<unknown>, ReturnValue>(
  action: (...args: Args) => Promise<ReturnValue>,
  options: Options<Args, ReturnValue> = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [value, setValue] = useState<ReturnValue | undefined>();

  const handleAction = async (...args: Args): Promise<ReturnValue> => {
    setIsLoading(true);
    try {
      const returnedValue = await action(...args);

      setValue(returnedValue);
      setIsLoading(false);

      options.onSuccess?.(args, returnedValue);

      return returnedValue;
    } catch (e) {
      setError(e);
      setIsLoading(false);

      options.onError?.(args, e);
    }
  };

  return { isLoading, handleAction, error, value };
}
