import { useApolloClient } from "@apollo/client";
import { useAuth } from "@clerk/nextjs";
import { GetToken } from "@clerk/types";
import { useRouter } from "next/router";
import React, { useMemo, ReactNode } from "react";
import { useEffect, useState } from "react";
import { useProviderDisplayNameQuery } from "@/graphql/queries/providerDisplayName.graphql.types";
import { useUserLazyQuery } from "@/graphql/queries/user.graphql.types";
import { PROVIDER } from "@/types";
import { AuthUser, UseUserResult, mapUserData } from "./useUser";

export const UserAuthContext = React.createContext<UseUserResult | null>(null);

export const UserAuthContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const { getToken, isLoaded, isSignedIn, userId } = useAuth();
  const client = useApolloClient();
  const [user, setUser] = useState<AuthUser>();
  const [medspa, setMedspa] = useState(
    router.query.medspaId ? String(router.query.medspaId) : null
  );
  const [loading, setLoading] = useState(true);
  const { data } = useProviderDisplayNameQuery({
    variables: {
      id: user?.id,
    },
    skip: !user?.id,
  });

  const [getUser, { data: additionalUserDetails }] = useUserLazyQuery();

  async function readToken(getToken: GetToken) {
    const userData = await mapUserData(getToken);

    setUser(userData);
    await getUser({
      variables: { id: userData.id },
    });
  }

  useEffect(() => {
    const readUser = async (getToken: GetToken) => {
      await readToken(getToken);
    };

    if (isLoaded) {
      if (isSignedIn) {
        readUser(getToken);
      }
      // clear the store/user only if was previously logged in
      if (!isSignedIn && userId === null && medspa) {
        client.clearStore();
        setUser(undefined);
        setMedspa(null);
      }
    }
    setLoading(false);
  }, [isLoaded, isSignedIn, additionalUserDetails, getToken, userId, medspa]);

  useEffect(() => {
    if (router.query.medspaId) {
      setMedspa(String(router.query.medspaId));
    }
  }, [router.query.medspaId]);

  const getMedspaIdFromAdditionalUserDetails = () => {
    const nonClientUserMedspa = additionalUserDetails?.userMedspa[0];
    return nonClientUserMedspa?.medspa.id;
  };

  const value = useMemo(
    () => ({
      user: user && {
        ...user,
        displayName: data?.userByPk?.firstName
          ? `${data.userByPk.firstName} ${data.userByPk.lastName}`
          : "Moxie User",
      },
      loading,
      medspa: medspa ?? getMedspaIdFromAdditionalUserDetails(),
      additionalUserDetails,
      providerUserMedspa: additionalUserDetails?.userMedspa.find(
        (um) => um.medspa.id === medspa && um.role === PROVIDER
      ),
      // We are assuming that we can only combine client+provider
      // client+MD or client+FD, no mix of these roles
      userMedspa: additionalUserDetails?.userMedspa.find(
        (um) => um.medspa.id === medspa
      ),
    }),
    [user, loading, medspa, data, additionalUserDetails]
  );

  return (
    <UserAuthContext.Provider value={value}>
      {children}
    </UserAuthContext.Provider>
  );
};
